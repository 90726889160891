import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 596.000000 508.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,508.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M2035 3810 c-386 -57 -675 -396 -675 -793 0 -170 81 -382 190 -500
23 -25 28 -26 39 -13 10 12 6 23 -28 66 -80 101 -135 237 -150 373 -26 227 49
444 208 606 285 292 739 303 1046 26 56 -50 60 -52 69 -29 4 10 -10 30 -43 60
-72 66 -92 80 -162 117 -157 83 -323 112 -494 87z"
          />
          <path
            d="M2035 3634 c-123 -26 -264 -107 -336 -193 -236 -284 -204 -661 77
-899 42 -36 113 -76 121 -69 2 3 -19 118 -48 256 -29 138 -49 254 -45 258 9 9
40 -26 107 -124 48 -70 64 -89 53 -59 -10 25 21 44 63 37 34 -6 38 -9 41 -40
2 -22 -2 -37 -13 -45 -15 -11 -15 -13 -1 -25 20 -17 20 -49 0 -72 -14 -16 -14
-21 5 -66 17 -40 33 -90 56 -169 2 -6 20 -11 40 -10 37 1 37 2 65 76 15 41 39
100 55 130 65 129 242 365 261 348 4 -5 -5 -54 -21 -110 -79 -292 -104 -392
-100 -396 12 -12 120 63 185 128 76 77 124 153 154 245 66 208 18 444 -125
602 -94 104 -184 158 -318 192 -97 24 -176 26 -276 5z m119 -379 c3 -8 3 -22
0 -30 -4 -8 -1 -15 4 -15 6 0 12 12 14 28 2 23 8 27 33 27 36 0 49 -33 17 -42
-13 -3 -19 -10 -15 -20 4 -10 9 -11 16 -4 6 6 25 11 43 11 36 0 50 -21 34 -50
-8 -15 -5 -19 15 -24 18 -5 25 -13 25 -31 0 -31 -36 -51 -74 -41 -20 5 -26 13
-26 34 0 15 5 33 12 40 9 9 8 12 -4 12 -9 0 -19 6 -21 13 -4 9 -8 9 -16 -2 -7
-11 -5 -18 6 -26 8 -6 13 -22 11 -36 -3 -22 -8 -24 -64 -27 -52 -2 -62 0 -66
15 -7 29 2 53 20 53 12 0 13 3 4 12 -7 7 -12 25 -12 40 0 15 -4 28 -10 28 -11
0 -14 33 -3 43 12 13 51 7 57 -8z m-64 -75 c0 -26 -4 -30 -27 -31 -27 0 -27
-1 -5 -9 16 -7 22 -18 22 -40 0 -31 0 -31 -47 -28 -46 3 -48 4 -48 33 0 23 5
31 20 33 17 2 20 9 17 37 -4 34 -3 35 32 35 33 0 36 -2 36 -30z m-25 -175 c0
-39 -1 -40 -38 -43 -44 -4 -67 11 -67 42 0 33 21 47 65 44 40 -3 40 -3 40 -43z
m160 0 l0 -40 -65 0 c-64 0 -65 0 -68 28 -6 52 2 58 70 55 l63 -3 0 -40z m125
26 c14 -28 13 -47 -7 -66 -16 -16 -16 -18 0 -27 20 -10 23 -58 5 -76 -13 -13
-56 -16 -82 -6 -22 8 -23 84 -1 84 8 0 15 5 15 10 0 6 -7 10 -15 10 -19 0 -21
76 -2 83 31 13 77 6 87 -12z m-285 -126 l0 -40 -44 -3 c-47 -3 -61 7 -61 44 0
31 21 45 65 42 40 -3 40 -3 40 -43z m163 2 c2 -26 -1 -39 -12 -43 -20 -8 -21
-24 -1 -24 19 0 21 -76 3 -83 -10 -4 -10 -7 0 -18 18 -20 15 -76 -5 -88 -17
-9 -17 -10 0 -16 20 -8 24 -64 5 -83 -7 -7 -33 -12 -58 -12 -25 0 -51 5 -58
12 -19 19 -15 75 6 83 16 6 16 7 0 16 -21 12 -24 68 -5 88 10 11 10 14 0 18
-20 7 -16 70 5 83 16 10 16 10 0 20 -18 11 -25 69 -11 84 4 4 34 6 68 4 l60
-3 3 -38z m126 -83 c7 -18 -20 -74 -35 -74 -6 0 -9 -7 -5 -15 6 -16 -30 -85
-45 -85 -15 0 -22 52 -10 73 9 14 10 22 2 30 -12 12 -15 70 -4 80 14 14 91 7
97 -9z m-154 -313 c0 -4 -5 -22 -11 -40 -18 -51 -60 -38 -71 22 -5 26 -4 27
38 27 24 0 44 -4 44 -9z"
          />
          <path
            d="M2140 2849 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"
          />
          <path
            d="M3053 3243 c-12 -2 -36 -17 -53 -33 -65 -60 -26 -158 69 -174 63 -10
81 -22 81 -56 0 -37 -29 -54 -76 -45 -28 5 -53 27 -69 62 0 1 -11 0 -23 -4
-44 -11 -19 -69 45 -102 34 -17 95 -13 133 10 45 28 50 35 50 87 0 57 -30 85
-110 102 -51 11 -61 17 -67 38 -9 36 8 57 50 60 27 2 41 -2 54 -17 16 -20 53
-28 65 -15 12 11 -5 45 -31 63 -29 21 -82 32 -118 24z"
          />
          <path
            d="M3981 3232 c-20 -38 31 -71 58 -38 9 11 10 20 1 35 -13 26 -46 27
-59 3z"
          />
          <path
            d="M4670 3071 l0 -181 30 0 c27 0 30 3 30 34 0 35 18 60 35 48 6 -4 21
-23 35 -44 18 -27 33 -37 57 -40 18 -2 33 -1 33 4 0 10 -63 102 -72 106 -17 8
-6 39 27 74 43 45 44 58 6 58 -21 0 -39 -12 -70 -45 -23 -25 -43 -45 -46 -45
-2 0 -6 46 -7 103 l-3 102 -27 3 -28 3 0 -180z"
          />
          <path
            d="M3333 3129 c-41 -12 -74 -56 -52 -70 22 -14 37 -11 49 11 13 24 52
26 74 5 25 -25 9 -40 -54 -53 -89 -19 -116 -69 -64 -117 28 -26 87 -34 99 -15
4 6 23 7 46 4 l39 -7 0 92 c0 105 -8 127 -55 147 -39 16 -38 16 -82 3z m80
-141 c16 -44 -49 -87 -81 -54 -27 27 6 60 64 65 7 1 14 -5 17 -11z"
          />
          <path
            d="M4460 3129 c-56 -23 -77 -62 -40 -74 14 -5 26 0 39 16 14 17 24 20
45 15 28 -7 45 -32 30 -46 -5 -5 -25 -11 -46 -14 -79 -12 -108 -43 -94 -100 7
-29 56 -51 93 -41 16 4 47 7 71 6 l42 -1 0 94 c0 87 -2 95 -26 120 -15 14 -32
26 -38 26 -6 0 -20 2 -31 5 -11 2 -31 0 -45 -6z m80 -152 c0 -56 -82 -79 -88
-25 -3 23 2 29 30 37 51 14 58 13 58 -12z"
          />
          <path
            d="M4983 3125 c-26 -11 -53 -41 -53 -58 0 -16 44 -13 61 4 20 20 45 24
67 10 20 -12 11 -45 -13 -49 -93 -16 -125 -37 -125 -82 0 -57 89 -92 133 -52
15 12 21 14 24 5 3 -7 16 -13 29 -13 24 0 24 1 24 93 0 107 -11 131 -68 147
-45 12 -40 12 -79 -5z m87 -150 c0 -32 -27 -57 -59 -53 -20 2 -27 9 -29 29 -3
22 2 29 24 36 49 16 64 14 64 -12z"
          />
          <path
            d="M3547 3123 c-4 -3 -7 -57 -7 -120 l0 -113 30 0 30 0 0 79 c0 86 12
111 54 111 38 0 46 -19 46 -107 l0 -83 30 0 30 0 0 79 c0 86 12 111 54 111 38
0 46 -19 46 -107 l0 -83 30 0 30 0 0 79 c0 87 -13 137 -40 151 -28 15 -83 12
-108 -6 -21 -15 -25 -15 -41 0 -20 18 -73 21 -103 5 -14 -8 -23 -8 -31 0 -12
12 -40 15 -50 4z"
          />
          <path
            d="M3987 3123 c-4 -3 -7 -57 -7 -120 l0 -113 30 0 30 0 0 120 c0 119 0
120 -23 120 -13 0 -27 -3 -30 -7z"
          />
          <path
            d="M4120 3009 l0 -120 26 3 c25 3 26 5 27 79 2 85 15 109 58 109 37 0
46 -27 48 -137 1 -50 3 -53 26 -53 25 0 25 0 25 98 0 90 -2 100 -23 120 -26
24 -85 30 -105 10 -9 -9 -15 -9 -24 0 -7 7 -22 12 -35 12 -23 0 -23 -1 -23
-121z"
          />
          <path
            d="M4617 2813 c-4 -3 -7 -35 -7 -70 l0 -64 46 3 c62 4 89 36 74 87 -5
19 -15 38 -22 43 -16 9 -82 11 -91 1z m81 -25 c7 -7 12 -24 12 -38 0 -32 -17
-50 -47 -50 -21 0 -23 4 -23 50 0 46 2 50 23 50 13 0 28 -5 35 -12z"
          />
          <path
            d="M4795 2811 c-14 -23 -45 -125 -39 -130 3 -3 10 2 15 12 13 23 68 23
85 0 17 -24 30 -8 16 20 -7 12 -18 40 -25 62 -14 39 -39 57 -52 36z m35 -57
c0 -14 -6 -24 -14 -24 -21 0 -25 11 -15 38 11 28 29 20 29 -14z"
          />
          <path
            d="M4890 2811 c0 -5 10 -11 23 -13 19 -3 22 -10 25 -60 4 -77 22 -75 22
2 0 53 2 60 20 60 11 0 20 5 20 10 0 6 -25 10 -55 10 -30 0 -55 -4 -55 -9z"
          />
          <path
            d="M5054 2785 c-7 -19 -21 -51 -28 -70 -13 -31 -13 -35 0 -35 7 0 14 7
14 15 0 11 11 15 40 15 29 0 40 -4 40 -15 0 -8 7 -15 15 -15 13 0 13 4 -1 38
-9 20 -20 52 -26 70 -12 42 -35 41 -54 -3z m38 -47 c-20 -20 -36 5 -21 33 l12
24 10 -23 c7 -16 7 -26 -1 -34z"
          />
          <path d="M1986 2746 c3 -9 11 -16 16 -16 13 0 5 23 -10 28 -7 2 -10 -2 -6 -12z" />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
